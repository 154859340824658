<template>
  <v-row no-gutters>
    <v-col>
      <div id="data-list" v-if="status">
        <data-filter v-model="isFilterDataSidebarActive" :sendData="sendData" @filter-data="filterData" />
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-xl'"
        >
          <v-toolbar
            :color="$vuetify.breakpoint.mdAndDown ? '#28243d' : 'transparent'"
            :flat="!$vuetify.breakpoint.smAndDown"
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiCreditCard }}
              </v-icon>
              Kart Hareketleri
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <download-excel
              v-if="!$vuetify.breakpoint.smAndDown"
              :data="json_data"
              :fields="json_fields"
              name="KART_HAREKETLERI__EKSUN.xls"
            >
              <v-btn plain text small>
                <v-icon left>
                  {{ icons.mdiDownload }}
                </v-icon>
                İndir
              </v-btn>
            </download-excel>
            <v-btn plain text small @click.stop="isFilterDataSidebarActive = !isFilterDataSidebarActive">
              <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
              </v-badge>
              <v-icon v-else left>
                {{ icons.mdiFilter }}
              </v-icon>
              Filitre
            </v-btn>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="dataListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            height="calc(100dvh - 236px)"
            :server-items-length="totalDataListTable"
            :loading="loading"
          >
            <template #[`item.customer`]="{ item }">
              <v-chip small label color="grey">
                <span> {{ item.customer }}</span>
              </v-chip>
            </template>
            <template #[`item.amount`]="{ item }">
              <span class="success--text text-caption font-weight-bold"> {{ item.amount | currency }}</span>
            </template>
            <template #[`item.installment_count`]="{ item }">
              <v-chip :color="item.installment_count === '1' ? 'info' : 'warning'" link outlined small label>
                {{ item.installment_count === '1' ? 'Tek Çekim' : item.installment_count + 'Taksit' }}
              </v-chip>
            </template>
            <template #[`item.cc_no`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.cc_no }}</span>
              </v-chip>
            </template>
            <template #[`item.datetime`]="{ item }">
              <span class="text--secondary text-caption"> {{ item.datetime.substring(0, 16) }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn color="chip" depressed class="text-capitalize" @click="downloadDekont(item.id)">Dekont</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiCreditCard,
  mdiDelete,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSend,
  mdiSquareEditOutline,
} from '@mdi/js'
import { ref, onMounted, watch } from '@vue/composition-api'
import Vue from 'vue'
import dataFilter from './DataFilter.vue'
import CustomerList from '@/components/CustomerList.vue'
import { postData } from '@utils'
export default {
  components: {
    dataFilter,
    CustomerList,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const sendData = ref({})
    onMounted(() => {
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        fetchDatas()
      }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          loading.value = true
          fetchDatas()
        }
      },
    )

    const isFilterNumber = ref(0)
    const isFilterDataSidebarActive = ref(false)
    const dataListTable = ref([])
    const dataItem = ref({})
    let tableColumns
    if (userData.role == 'SUPER' || userData.role == 'PLSYR') {
      tableColumns = [
        {
          text: 'ID',
          value: 'id',
          width: '90px',
          sortable: false,
        },
        {
          text: 'Müşteri No',
          value: 'customer',
          width: '120px',
        },
        {
          text: 'Müşteri',
          value: 'customer_name',
          align: 'left min-width-400',
        },
        {
          text: 'Tutar',
          value: 'amount',
          width: '150px',
          align: 'right',
          sortable: false,
        },

        {
          text: 'Taksit Sayısı',
          value: 'installment_count',
          width: '150px',
          align: 'center',
          sortable: false,
        },

        {
          text: 'Kart No',
          value: 'cc_no',
          sortable: false,
          width: '200px',
        },

        {
          text: 'İşlem Tarihi',
          value: 'datetime',
          sortable: false,
          width: '200px',
        },
        {
          text: '',
          value: 'actions',
          width: '100px',
        },
      ]
    } else {
      tableColumns = [
        {
          text: 'ID',
          value: 'id',
          width: '90px',
          sortable: false,
        },
        {
          text: 'Kart No',
          value: 'cc_no',
          sortable: false,
          align: 'start min-width-400',
        },

        {
          text: 'Tutar',
          value: 'amount',
          width: '150px',
          align: 'right',
          sortable: false,
        },

        {
          text: 'Taksit Sayısı',
          value: 'installment_count',
          width: '150px',
          sortable: false,
        },
        {
          text: 'İşlem Tarihi',
          value: 'datetime',

          width: '200px',
        },
        {
          text: '',
          value: 'actions',
          width: '100px',
        },
      ]
    }

    const searchQuery = ref('')

    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])

    const json_data = ref([])

    const fetchDatas = () => {
      const query = {
        options: options.value,
        't.id': idFilter.value,
      }
      const method = 'getPaymentActions'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          if (response.error == 0) {
            dataListTable.value = response.response.result
            totalDataListTable.value = Number(response.response.pagination.totalRec)
            json_data.value = response.response.result

            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]
    const downloadDekont = id => {
      postData({
        method: 'getPaymentSlip',
        id: id,
      }).then(response => {
        if (response.error === 0) {
          window.open(response.path)
        }
      })
    }
    const idFilter = ref(null)
    const filterData = item => {
      idFilter.value = item.id || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
      sendData.value = Object.assign({}, item)
    }
    watch([searchQuery, idFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchDatas()
    })

    return {
      sendData,
      statusFind,
      json_data,
      dataItem,
      isFilterNumber,
      filterData,
      dataListTable,
      tableColumns,
      searchQuery,
      idFilter,
      totalDataListTable,
      loading,
      options,
      dataTotalLocal,
      isFilterDataSidebarActive,
      selectedRows,
      fetchDatas,
      status,
      userData,
      downloadDekont,
      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiCreditCard,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiDownload,
        mdiSend,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        ID: 'id',
        'Kart No': 'cc_no',

        Fiyat: {
          field: 'amount',
          callback: value => {
            return `${Number(value.slice(0, -1))}`
          },
        },
        'Taksit Sayısı': {
          field: 'installment_count',
          callback: value => {
            return value == 1 ? 'Tek Çekim' : value + 'Taksit'
          },
        },
        'İşlem Tarihi': {
          field: 'datetime',
          callback: value => {
            return `${value.slice(0, 16)}`
          },
        },
        Açıklama: 'ltext',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
